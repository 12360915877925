import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCc_mbt69AAaIOO-G487r4R9iCOS-i1S3E",
    authDomain: "codinghero-sg.firebaseapp.com",
    databaseURL: "https://codinghero-sg.firebaseio.com",
    projectId: "codinghero-sg",
    storageBucket: "codinghero-sg.appspot.com",
    messagingSenderId: "730725301865",
    appId: "1:730725301865:web:997fdc4878e8fc6d616cf8",
    measurementId: "G-WGHJV350XZ"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore()
export var storage = firebaseApp.storage()
export var auth = firebaseApp.auth()
export var functions = firebaseApp.functions()




<template>
  <div>
    <v-card>
      <v-toolbar color="cyan" dark flat>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Admin</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon style="margin-right: 10px;">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn @click="toggle()">
          {{toggleStateButton}}
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-data-table :headers="headers" :items="getAssignments" :items-per-page="10" class="elevation-1">
            <template v-slot:item.phone="{ item }">
              <v-btn @click="whatsappClient(item.dialCode, item.phone)" style="color: white;" color="green">
                {{item.phone}}</v-btn>
            </template>
            <template v-slot:item.dueDate="{ item }">
              {{formatDueDate(item.dueDate)}}
            </template>
            <template v-slot:item.description="{ item }">
              {{limitWords(item.description)}}
            </template>
            <template v-slot:item.allocate="{ item }">
              <v-btn v-if="items[tab] == 'Pending'" @click="onSubmitClicked(item)">Submit</v-btn>
              <v-btn v-if="items[tab] == 'Pending Allocation'" @click="onSubmitClicked(item)">Allocate</v-btn>
              <v-btn v-if="items[tab] == 'Allocated'" @click="onSubmitClicked(item)">Complete</v-btn>
              <v-btn v-if="items[tab] == 'Completed' || items[tab] == 'Cancelled'" @click="onSubmitClicked(item)">View
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>


    <v-dialog v-model="submitDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="selectedRequest">
        <v-toolbar dark color="cyan">
          <v-btn icon dark @click="onDialogClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="selectedRequest"><strong>{{selectedRequest.nice_id}}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn style="background-color: green" v-if="items[tab] == 'Pending'" dark text :loading="submitLoading"
              @click="onConfirmSubmit()">
              Submit
            </v-btn>
            <v-btn style="background-color: yellow; color: black;" v-if="items[tab] == 'Pending Allocation'" dark text
              :loading="submitLoading" @click="onConfirmAllocate()">
              Allocate
            </v-btn>
            <v-btn style="background-color: Purple;" v-if="items[tab] == 'Allocated'" dark text :loading="submitLoading"
              @click="onConfirmCompleted()">
              Completed
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list>
          <v-subheader>Created on: <strong> {{formatCreatedDate(selectedRequest.created)}}</strong></v-subheader>
          <v-subheader>Resources</v-subheader>
          <v-list-item v-for="file in selectedRequest.files" :key="file">
            <v-list-item-content>
              <a :href="file" target="_blank">{{file}}</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-subheader>Details</v-subheader>
          <v-list-item>
            <v-list-item-title>Status</v-list-item-title>
            <v-select light :items="[
              'Pending',
              'Pending Allocation',
              'Allocated',
              'Completed',
            ]" v-model="selectedRequest.requestState" label="Status" solo></v-select>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>{{selectedRequest.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Phone</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field type="number" prepend-inner-icon="mdi-cash" dark solo v-model="selectedRequest.phone">
                </v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Telegram</v-list-item-title>
              <v-list-item-subtitle>{{selectedRequest.telegram}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Language</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field prepend-inner-icon="mdi-cash" dark solo v-model="selectedRequest.progLang"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Task Type</v-list-item-title>
              <v-list-item-subtitle>{{selectedRequest.taskType}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Due on</v-list-item-title>
              <v-list-item-subtitle>{{formatDueDate(selectedRequest.dueDate)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea dark solo v-model="selectedRequest.description">
                </v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Coder Amount</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field type="number" prepend-inner-icon="mdi-cash" dark solo
                  v-model="selectedRequest.amountCoder">
                </v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Client Amount</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field type="number" prepend-inner-icon="mdi-cash" dark solo
                  v-model="selectedRequest.amountClient">
                </v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Deposited</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field type="number" prepend-inner-icon="mdi-cash" dark solo
                  v-model="selectedRequest.amountDeposited">
                </v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="items[tab] != 'Pending'">
            <v-list-item-content>
              <v-list-item-title>Coder Allocated</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field type="Text" prepend-inner-icon="mdi-account" dark solo
                  v-model="selectedRequest.coderAllocated">
                </v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div style="padding-left: 20px; padding-right: 20px;">
          <v-btn :loading="updateLoading" @click="onConfirmUpdate()" style="color: white;" block color="green">Update
          </v-btn>
        </div>
        <div style="padding-top: 70px; padding-left: 20px; padding-right: 20px;">
          <v-btn :loading="cancelLoading" @click="cancelRequest()" style="color: white;" block color="red">Cancel
          </v-btn>
        </div>
        <br>
        <br>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import db, { storage, functions } from "@/firebase/init";
  export default {
    data () {
      return {
        updateLoading: false,
        submitLoading: false,
        cancelLoading: false,

        selectedRequest: null,
        submitDialog: false,
        toggleStateButton: 'Assignment',

        assignments: [],
        softwares: [],
        tuitions: [],

        tab: 'Pending',
        items: [
          'Pending', 'Pending Allocation', 'Allocated', 'Completed', 'Cancelled',
        ],
        headers: [
          {
            text: 'ID',
            value: 'nice_id',
          },
          { text: 'Type', value: 'taskType' },
          { text: 'Language', value: 'progLang' },
          { text: 'Description', value: 'description' },
          { text: 'Due', value: 'dueDate' },
          { text: 'Contact', value: 'phone' },
          {text: 'Coder Allocated', value: 'coderAllocated'},
          { text: '', value: 'allocate' },
        ],
      }
    },
    computed: {
      getAssignments(){
        console.log("this.tab: ", this.tab)
        return this.assignments.filter(assignment => assignment.requestState == this.items[this.tab])
        .sort((a,b) => {
          return b.created - a.created
        })
      }
    },
    async created() {
      await this.init()
    },
    methods: {
      limitWords(description){
        if(description && description.length < 400)
          return description
        return description.substring(0,400) +"..."
      },
      async cancelRequest(){
        this.cancelLoading = true
        await db.collection('assignments').doc(this.selectedRequest.id).update({
          requestState: 'Cancelled'
        })
        this.cancelLoading = false        
        this.submitDialog = false
        this.selectedRequest = null
      },
      formatCreatedDate(date){
        return moment(date).format('LLLL')
      },
      formatDueDate(date){
        return moment(date).format('dddd, DD MMM YYYY')
      },
      async init(){
        this.assignments = []
        this.softwares = []
        this.tuitions = []
        if(this.toggleStateButton == 'Assignment'){
          var snap = await db.collection('assignments')
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach(async change => {
                    var doc = await change.doc;
                    var assignmentData = doc.data();
                    assignmentData.id = doc.id;
                    console.log("assignmentData: ", assignmentData);
                    if (change.type == "added") {
                      if(!this.assignments.find(assignment => assignment.id == assignmentData.id)){
                        this.assignments.push(assignmentData)
                      }
                    }
                    if (change.type == "modified") {
                      this.assignments = this.assignments.filter(assignment => {
                        return assignment.id != assignmentData.id
                      })
                      if(!this.assignments.find(assignment => assignment.id == assignmentData.id)){
                        this.assignments.push(assignmentData)
                      }
                    }
                    if (change.type == "removed") {
                      this.assignments = this.assignments.filter(assignment => {
                        return assignment.id != assignmentData.id
                      })
                    }
                });
            })
        }
        if(this.toggleStateButton == 'Software'){

        }
        if(this.toggleStateButton == 'Tuition'){

        }
      },
      async onConfirmUpdate() {
        this.updateLoading = true
        console.log("Hello: ", this.selectedRequest)
        await db.collection('assignments').doc(this.selectedRequest.id).update(this.selectedRequest)
        this.updateLoading = false
      },
      async onConfirmCompleted() {
        this.submitLoading = true
        console.log("Hello: ", this.selectedRequest)
        this.selectedRequest.requestState = 'Completed'
        await db.collection('assignments').doc(this.selectedRequest.id).update(this.selectedRequest)
        this.submitLoading = false
        this.submitDialog = false
        this.selectedRequest = null
      },
      async onConfirmAllocate() {
        this.submitLoading = true
        console.log("Hello: ", this.selectedRequest)
        this.selectedRequest.requestState = 'Allocated'
        await db.collection('assignments').doc(this.selectedRequest.id).update(this.selectedRequest)
        this.submitLoading = false
        this.submitDialog = false
        this.selectedRequest = null
      },
      async onConfirmSubmit() {
        this.submitLoading = true
        console.log("Hello: ", this.selectedRequest)
        this.selectedRequest.requestState = 'Pending Allocation'
        await db.collection('assignments').doc(this.selectedRequest.id).update(this.selectedRequest)
        var payload
        if(this.selectedRequest.amountCoder == null || this.selectedRequest.amountCoder == ''){
          payload =  {
            id: this.selectedRequest.id,
            taskType: this.selectedRequest.taskType,
            progLang: this.selectedRequest.progLang,
            description: this.selectedRequest.description,
            dueDate: this.selectedRequest.dueDate,
            amountCoder: 'TBA'
          }
        } else {
          payload =  {
            id: this.selectedRequest.id,
            taskType: this.selectedRequest.taskType,
            progLang: this.selectedRequest.progLang,
            description: this.selectedRequest.description,
            dueDate: this.selectedRequest.dueDate,
            amountCoder: "$"+this.selectedRequest.amountCoder
          }
        }
        var sendToCHGroup = functions.httpsCallable("sendToCHGroup");
        await sendToCHGroup({ref: this.selectedRequest.nice_id, payload: payload});
        this.submitLoading = false
        this.submitDialog = false
        this.selectedRequest = null
      },
      onDialogClose() {
        this.submitDialog = false
        this.selectedRequest = null
      },
      onSubmitClicked(request) {
        this.submitDialog = true
        this.selectedRequest = request
      },
      whatsappClient(countryCode,phoneNumber){
        console.log(countryCode)
        if(countryCode === undefined || countryCode === null){
          window.open("https://wa.me/65"+phoneNumber+"?text=Hello!%20Thank%20you%20for%20submitting%20a%20request%20to%20Coding%20Hero" ,"_self")
        }else{
          window.open("https://wa.me/"+countryCode+ ""+phoneNumber+"?text=Hello!%20Thank%20you%20for%20submitting%20a%20request%20to%20Coding%20Hero" ,"_self")
        }
        
      },
      toggle() {
        if(this.toggleStateButton == 'Assignment'){
          this.toggleStateButton = 'Software'
          return
        }
        if(this.toggleStateButton == 'Software'){
          this.toggleStateButton = 'Tuition'
          return
        }
        if(this.toggleStateButton == 'Tuition'){
          this.toggleStateButton = 'Assignment'
          return
        }
      }
    }
  }
</script>

<style>
</style>
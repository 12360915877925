import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Assignment from '../views/Assignment.vue'
import Success from '../views/Success.vue'
import Admin from '../views/Admin.vue'
import AssignmentDetails from '../views/AssignmentDetails.vue'
import Metrics from '../views/Metrics.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/assignment',
    name: 'Assignment',
    component: Assignment
  },
  {
    path: '/assignment/:id',
    name: 'AssignmentDetails',
    component: AssignmentDetails
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/admin/94562307-josh',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

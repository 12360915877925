<template>
  <v-app>
    <v-main id="main-app">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style scoped>
  #main-app{
    background-color: #EFEFEF;
    font-family: 'Poppins', sans-serif;
  }
</style>

<template>
  <div>
    <v-layout class="d-flex justify-center">
      <img height="70" alt="Vue logo" src="../assets/logo.png" />
    </v-layout>
    <v-layout>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center">
          <div style="padding: 10px;">
            <v-card elevation="10" max-width="500" color="#5B62FB" dark>
              <v-card-title style="font-weight: 600; font-size: 18px;">Coding Assignment Help Request</v-card-title>

              <v-card-subtitle style="font-weight: 300;">
                Do prepare your assignment materials and a detailed description on what has to be accomplished. We will
                reply back to you via Whatsapp/Email within an hour with a quotation and job allocation to one of our
                experienced coders.
                <br />
                <br />
                <strong style="color: white;">Do ensure your contact details are correct!</strong>
                <br />
                <br />
                <strong style="color: white;">*required fields</strong>
              </v-card-subtitle>

              <v-card-text>


                <v-row justify="start" class="flex-no-wrap"
                  style="padding-left: 10px; padding-right: 0px; margin-top: -35px;">
                  <v-col cols="auto" align-self="center" style="margin-left: -10px; margin-right: -20px;">
                    <vue-country-code
                      style="background-color: rgb(256,256,256); color:rgb(0,0,0 ); border-radius: 6px;margin-bottom: 6px;"
                      @onSelect="onSelect" :onlyCountries="['us', 'sg']" defaultCountry="sg" />
                  </v-col>
                  <v-col align-self="start">
                    <input disabled type="number" min="0" inputmode="numeric" pattern="[0-9]*"
                      title="Non-negative integral number">
                    <v-text-field v-model="phone" min="0" inputmode="numeric" pattern="[0-9]*" type="number" light
                      label="*Phone Number (WhatsApp)" solo prepend-inner-icon="mdi-phone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; margin-top: -25px; padding-right: 10px;">
                  <v-text-field v-model="email" type="email" light label="*Email" solo prepend-inner-icon="mdi-email">
                  </v-text-field>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; margin-top: -15px; padding-right: 10px;">
                  <v-text-field v-model="telegram" type="text" light label="Telegram (optional)" solo
                    prepend-inner-icon="mdi-at"></v-text-field>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; margin-top: -15px; padding-right: 10px;">
                  <v-select light :items="[
                    'Assignment',
                    'Project',
                    'Exam/Quiz',
                    'FYP',
                    'Internship',
                  ]" v-model="taskType" label="*Coding Task Type" solo></v-select>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; padding-right: 10px;">
                  <v-text-field light label="Programming Languages Used e.g. Java, Python, SQL...)" solo
                    v-model="progLang"></v-text-field>
                </v-row>
                <v-row style="padding-left: 10px; margin-top: -15px; padding-right: 10px;">
                  <p style="font-weight: 300;">Please give a detailed description of what needs to be coded out.</p>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; padding-right: 10px;">
                  <v-textarea light label="*Assignment Description..." solo v-model="description"></v-textarea>
                </v-row>
                <v-row style="padding-left: 10px; margin-top: -15px; padding-right: 10px;">
                  <p style="font-weight: 300;">
                    Give a due date of when the assignment should be completed, rule of thumb is if the
                    due date is nearer, quotation will be slightly higher.
                    <br />
                    <br />
                    <strong>If due date includes time, enter it in description above.</strong>
                  </p>
                </v-row>
                <v-row style="padding-left: 10px; padding-right: 10px;">
                  <v-dialog ref="dialog" v-model="modal" :return-value.sync="dueDate" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dueDate" label="Due Date" prepend-icon="mdi-calendar" solo readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dueDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(dueDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-row>
                <v-row style="padding-left: 10px; margin-top: -15px; padding-right: 10px;">
                  <p style="font-weight: 300;">
                    Please upload all relevant files related to your request! Examples are:
                    PDFs, Assignment briefs, notes, Lecture Slides, Screenshots/Photos of examples etc...
                  </p>

                  <p>You can upload multiple files! (shift+click on desktop or select multiple on mobile).</p>
                </v-row>
                <v-row class="d-flex" style="padding-left: 10px; margin-top: 0px; padding-right: 10px;">
                  <v-file-input label="Supporting Files" dark chips placeholder="Upload relevant files" counter multiple
                    solo v-model="files"></v-file-input>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" @click="submit" style="font-size: 20px;" text>
                  <strong>Submit!</strong>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <v-dialog v-model="error" persistent max-width="290">
      <v-card>
        <v-card-title color="red darken-1" class="headline">Error!</v-card-title>
        <v-card-text>Please fill up all the fields!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="error = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import db, { storage, functions } from "@/firebase/init";
import moment from "moment";
export default {
  name: "Assignment",
  data() {
    return {
      modal: false,
      
      phone: null,
      email: null,
      telegram: null,
      taskType: null,
      progLang: null,
      description: null,
      dueDate: null,
      country: '',
      dialCode: null,
      files: [],

      error: false,
      loading: false
    };
  },
  methods: {
    onSelect({name, iso2, dialCode}){
      this.country=iso2;
      this.dialCode=dialCode;
    },
    async submit() {
      if (this.country && this.phone && this.email && this.taskType && this.description) {
        this.loading = true;
        var links = [];
        gtag_report_conversionSubmitAssignmentForm();
        var res = await db.collection("assignments").add({
          country: this.country,
          dialCode: this.dialCode,
          phone: this.phone,
          email: this.email,
          telegram: this.telegram,
          taskType: this.taskType,
          progLang: this.progLang,
          description: this.description,
          dueDate: this.dueDate,
          files: null,
          requestState: "Pending",
          amountClient: null,
          amountCoder: null,
          amountDeposited: null,
          created: Date.now()
        });
        //update nice id
        var now = moment().format("MMYY");
        var subId = res.id.substring(0, 5);
        var nice_id = now + "-" + subId;

        //upload each file
        for (var file of this.files) {
          var timestamp = Date.now();
          var snap = await storage
            .ref()
            .child("/resources/"+nice_id+"/" + file.name)
            .put(file);
          var downloadUrl = await snap.ref.getDownloadURL();
          links.push(downloadUrl);
        }
        await db
          .collection("assignments")
          .doc(res.id)
          .update({
            nice_id: nice_id,
            files: links
          });
        //send confirmation email
        var sendEmail = functions.httpsCallable("sendEmail");
        var sendToChannel = functions.httpsCallable("sendToChannel");
        var payload =  {
          phone: this.phone,
          email: this.email,
          telegram: this.telegram,
          taskType: this.taskType,
          progLang: this.progLang,
          description: this.description,
          dueDate: this.dueDate,
          files: links,
        }
        await sendEmail({ email: this.email, ref: nice_id, payload: payload});
        await sendToChannel({ref: nice_id, payload: payload});

        this.$router.push({name: 'Success', params: {ref: nice_id}}).then(() => window.scrollTo(0, 0));
        this.loading = false;
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-layout class="d-flex justify-center">
      <img height="70" alt="Vue logo" src="../assets/logo.png" />
    </v-layout>
    <v-layout>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center">
          <div style="padding: 10px;">
            <v-card elevation="10" min-width="300" max-width="500" color="white" dark>
              <v-card-title
                class="d-flex justify-center"
                style="font-weight: 600; font-size: 18px;"
              >
                <div class="sa">
                  <div class="sa-success">
                    <div class="sa-success-tip"></div>
                    <div class="sa-success-long"></div>
                    <div class="sa-success-placeholder"></div>
                    <div class="sa-success-fix"></div>
                  </div>
                </div>
              </v-card-title>
              <v-card-title
                class="d-flex justify-center"
                style="margin-top: -40px; font-weight: 600; font-size: 21px; color: #385F73;"
              >Thank you!</v-card-title>
              <v-card-text class="d-flex justify-center" style="margin-top: -15px; color: #385F73;">
                Here is your reference number: 
              </v-card-text>
              <v-card-text class="d-flex justify-center" style="font-weight: 800; font-size:40px; margin-top: -20px; color: #385F73;">
                {{ref}}
              </v-card-text>
              <v-card-text class="d-flex justify-center" style="text-align:center; font-weight: 300; margin-top: 0px; color: #385F73;">
                A confirmation email has been sent with your request details.
                <br>We will respond with a quoataion soon through WhatsApp. We thank you for your patience.
                <br>
                <br>
                If you require further clarifications do not hesitate to WhatsApp us! ⬇
              </v-card-text>

              <v-card-actions>
                <v-btn block color="green" @click="whatsappUs()">
                        <v-icon left>
                          mdi-whatsapp
                        </v-icon>
                  WhatsApp Us here</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-layout>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <v-btn max-width="500" @click="backToCoding">
                <v-icon
                    left
                    dark
                >
                    mdi-arrow-left
                </v-icon>
                Back to Codingo.sg
            </v-btn>
        </v-layout>
      <br>
      <br>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <p style="font-size:15px; font-weight: 300; margin-top: -20px;">
                Built with ❤️ in Singapore by Codingo.
            </p>
        </v-layout>
      <br>
      <br>
      <br>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        ref: null
      }
    },
    created() {
      this.ref = this.$route.params.ref
    },
    methods: {
      whatsappUs() {
        window.open("https://wa.me/6594562307?text=Hello!%20My%20reference%20number%20is%20"+this.ref+"%20Do%20assist%20me%20with%20my%20assignment!" ,"_self")
      },
      backToCoding() {
          window.open("https://www.codingo.sg","_self")
      }
    }
  }
</script>
<style lang="scss">
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
  &-success {
    border-radius: 50%;
    border: 4px solid #a5dc86;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: #fff;
    width: 80px;
    &:after,
    &:before {
      background: #fff;
      content: "";
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }
    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }
    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }
    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgba(165, 220, 134, 0.25);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }
    &-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }
    &-tip,
    &-long {
      background-color: #a5dc86;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 2;
    }
    &-tip {
      left: 14px;
      top: 46px;
      transform: rotate(45deg);
      width: 25px;
      animation: animateSuccessTip 0.75s;
    }
    &-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px;
      animation: animateSuccessLong 0.75s;
    }
  }
}
@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
</style>

<template>
    <div>
    <v-layout class="d-flex justify-center">
    <img height="70" alt="Vue logo" src="../assets/logo.png">
    </v-layout>
    <v-layout class="d-flex justify-center">
        <p style="font-size:17px; font-weight: 300; margin-top: -20px;">
            Requests Metrics {{this.year}}
        </p>
    </v-layout>
      <v-layout>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div style="padding: 10px;">
              <line-chart :key="chartKey" style="width: 500px" :chart-data="getChartData"></line-chart>
            </div>
          </v-col>
        </v-row>
      </v-layout>
      <v-layout>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="year = '2020'" style="margin-right: 10px">2020</v-btn>
                <v-btn @click="year = '2021'" style="margin-right: 10px">2021</v-btn>
                <v-btn @click="year = '2022'" style="margin-right: 10px">2022</v-btn>
                <v-btn @click="year = '2023'" style="margin-right: 10px">2023</v-btn>
                <v-btn @click="year = '2024'" style="margin-right: 10px">2024</v-btn>
            </v-col>
        </v-row>
      </v-layout>
      <br>
      <br>
      <br>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <v-btn max-width="500" @click="backToCoding">
                <v-icon
                    left
                    dark
                >
                    mdi-arrow-left
                </v-icon>
                Back to Codingo.sg
            </v-btn>
        </v-layout>
      <br>
      <br>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <p style="font-size:15px; font-weight: 300; margin-top: -20px;">
                Built with ❤️ in Singapore by Codingo.
            </p>
        </v-layout>
      <br>
      <br>
      <br>
  </div>
</template>

<script>
  import LineChart from './LineChart.js'
  import db, { storage, functions } from "@/firebase/init";
  import moment from 'moment'
  export default {
    components: {
      LineChart
    },
    data () {
      return {

        assignments: [],
        year: '2022',
        chartKey: 0,
        monthData: [
          this.getRandomInt(), 
          this.getRandomInt(),
          this.getRandomInt(),
           this.getRandomInt(), 
          this.getRandomInt(),
          this.getRandomInt(),
           this.getRandomInt(), 
          this.getRandomInt(),
          this.getRandomInt(),
           this.getRandomInt(), 
          this.getRandomInt(),
          this.getRandomInt(),
        ],
      }
    },
    async created () {
      //get all request data from firebase
      var snap = await db.collection('assignments')
        .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(async change => {
                var doc = await change.doc;
                var assignmentData = doc.data();
                assignmentData.id = doc.id;
                console.log("assignmentData: ", assignmentData);
                if (change.type == "added") {
                  if(!this.assignments.find(assignment => assignment.id == assignmentData.id)){
                    this.assignments.push(assignmentData)
                  }
                }
                if (change.type == "modified") {
                  this.assignments = this.assignments.filter(assignment => {
                    return assignment.id != assignmentData.id
                  })
                  if(!this.assignments.find(assignment => assignment.id == assignmentData.id)){
                    this.assignments.push(assignmentData)
                  }
                }
                if (change.type == "removed") {
                  this.assignments = this.assignments.filter(assignment => {
                    return assignment.id != assignmentData.id
                  })
                }
                this.chartKey++
            });
        })
    },
    computed: {
      //function to build arrays of months for specified year
      getChartData() {
        var monthlyCount = [0,0,0,0,0,0,0,0,0,0,0,0]
        console.log('this.assignments: '+this.assignments)
        if(this.assignments.length > 0) {
          this.assignments.forEach(assignment => {
            if(moment(assignment.created).format('YYYY') == this.year) { //check for current year
             var mon = moment(assignment.created).format('MMM')
             console.log('mon: '+mon)
             if(mon == 'Jan'){
               monthlyCount[0]+=1
             }
            if(mon == 'Feb'){
               monthlyCount[1]+=1
             }
            if(mon == 'Mar'){
               monthlyCount[2]+=1
             }
            if(mon == 'Apr'){
               monthlyCount[3]+=1
             }
            if(mon == 'May'){
               monthlyCount[4]+=1
             }
            if(mon == 'Jun'){
               monthlyCount[5]+=1
             }
            if(mon == 'Jul'){
               monthlyCount[6]+=1
             }
            if(mon == 'Aug'){
               monthlyCount[7]+=1
             }
            if(mon == 'Sep'){
               monthlyCount[8]+=1
             }
             if(mon == 'Oct'){
               monthlyCount[9]+=1
             }
             if(mon == 'Nov'){
               monthlyCount[10]+=1
             }
             if(mon == 'Dec'){
               monthlyCount[11]+=1
             }
            }
          })
        }
        return {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Requests',
              backgroundColor: '#FFB88C',
              data: monthlyCount
            },
          ]
        }
      }
    },
    methods: {
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    }
  }
</script>

<style>

</style>
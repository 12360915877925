<template>
    <div>
    <v-layout class="d-flex justify-center">
    <img height="70" alt="Vue logo" src="../assets/logo.png">
    </v-layout>
    <v-layout class="d-flex justify-center">
        <p style="font-size:17px; font-weight: 300; margin-top: 10px;">
            Select one of the following services:
        </p>
    </v-layout>
      <v-layout>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div style="padding: 10px;">
            <v-card
             @click="goToAssignment"
            hover
            elevation="12"
              max-width="500"
              color="#385F73"
              dark
            >

            <v-list-item two-line>
            <v-list-item-content>
                    <h2 style="font-weight: 600; ">
                    Coding Assignment Help
                    </h2>
                <p style="font-size:15px; font-weight: 300; color: #E0E0E0">Are you a student stuck on a coding assignment? Let our expert coders assist you in completing it!</p>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
            <v-img src="../assets/binary-code.png"></v-img>
            </v-list-item-avatar>
            </v-list-item>
          </v-card>
            </div>
          </v-col>
        </v-row>
      </v-layout>
    <v-layout>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div style="padding: 10px;">
            <v-card
             @click="goToSoftware"
            hover
            elevation="12"
              max-width="500"
              color="#FCE178"
              dark
            >

            <v-list-item two-line>
            <v-list-item-content>
                    <h2 style="font-weight: 600; color: #424242">
                        Software Development
                    </h2>
                <p style="font-size:15px; font-weight: 300; color: #424242">Need a custom app or website? Let us know here!</p>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
            <v-img src="../assets/coding.png"></v-img>
            </v-list-item-avatar>
            </v-list-item>
          </v-card>
            </div>
          </v-col>
        </v-row>
      </v-layout>
        <v-layout>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div style="padding: 10px;">
            <v-card
             @click="goToTuition"
            hover
            elevation="12"
              max-width="500"
              color="#ED906A"
              dark
            >

            <v-list-item two-line>
            <v-list-item-content>
                    <h2 style="font-weight: 600; color: #424242" >
                        1-1 Coding Tuition
                    </h2>
                <p style="font-size:15px; font-weight: 300; color: #424242">
                    Are you looking for 1 to 1 guidance in coding? Schedule a virtual video lesson with our friendly coders now!
                </p>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
            <v-img src="../assets/info.png"></v-img>
            </v-list-item-avatar>
            </v-list-item>
          </v-card>
            </div>
          </v-col>
        </v-row>
      </v-layout>
      <br>
      <br>
      <br>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <v-btn max-width="500" @click="backToCoding">
                <v-icon
                    left
                    dark
                >
                    mdi-arrow-left
                </v-icon>
                Back to Codingo.sg
            </v-btn>
        </v-layout>
      <br>
      <br>
      <br>
      <br>
        <v-layout class="d-flex justify-center">
            <p style="font-size:15px; font-weight: 300; margin-top: -20px;">
                Built with ❤️ in Singapore by Codingo.
            </p>
        </v-layout>
      <br>
      <br>
      <br>
  </div>
</template>

<script>
export default {
    methods: {
        goToAssignment(){
            this.$router.push({name: 'Assignment'}).then(() => window.scrollTo(0, 0));
        },
        goToSoftware(){
            this.$router.push({name: 'Software'}).then(() => window.scrollTo(0, 0));
        },        
        goToTuition(){
            this.$router.push({name: 'Tuition'}).then(() => window.scrollTo(0, 0));
        },
        backToCoding() {
            window.open("https://www.codingo.sg","_self")
        }
    }
}
</script>

<style>

</style>
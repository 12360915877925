<template>
  <div>
    <v-card v-if="assignment">
        <v-toolbar
          dark
          color="cyan"
        >
          <v-toolbar-title v-if="assignment"><strong>{{assignment.nice_id}}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
              <v-chip v-if="assignment.requestState == 'Allocated'" color="red" style="margin-top: 10px;">Taken...</v-chip>
              <v-chip v-else color="green" style="margin-top: 10px;">Available!</v-chip>
          </v-toolbar-items>
        </v-toolbar>
        <v-list style="margin-top: 10px;">
          <v-list-item>
          <p style="font-weight: 300; font-size: 12px;">Created on: {{formatCreatedDate(assignment.created)}}</p>
          </v-list-item>
        </v-list>
        <v-divider style="margin-top: -10px;"></v-divider>
        <v-list>
          <v-subheader>Assignment Details</v-subheader>
          <v-list-item style="margin-top: -10px;">
            <v-list-item-content>
              <v-list-item-title>Language</v-list-item-title>
              <v-list-item-subtitle>{{assignment.progLang}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Task Type</v-list-item-title>
              <v-list-item-subtitle>{{assignment.taskType}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Due on</v-list-item-title>
              <v-list-item-subtitle>{{formatDueDate(assignment.dueDate)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <p style="font-weight: 300">{{assignment.description}}</p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Coder Pay Amount ($)</v-list-item-title>
              <v-list-item-subtitle>
                <v-list-item-subtitle v-if="assignment.amountCoder == null || assignment.amountCoder == ''" style="font-size: 20px;">TBA</v-list-item-subtitle>
                <v-list-item-subtitle v-else style="font-size: 20px;">${{assignment.amountCoder}}</v-list-item-subtitle>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-subheader>Resources</v-subheader>
          <v-list-item v-for="file in assignment.files" :key="file">
            <v-list-item-content>
              <a :href="file" target="_blank">{{file}}</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-subheader>Take this assignment by messaging me using either buttons below! Feel free to ask more questions! ⬇️</v-subheader>
        <div style="padding-left: 20px; padding-right: 20px; margin-bottom: 20px; margin-top: 20px;">
        <v-btn style="color:white; margin-top: 10px;" block color="green" @click="whatsapp()">
                <v-icon left>
                    mdi-whatsapp
                </v-icon>
            Take this assignment!
        </v-btn>
        <v-btn style="color:white; margin-top: 10px;" block color="blue" @click="telegram()">
            <v-icon left>
                mdi-telegram
            </v-icon>
            Take this assignment!
        </v-btn>
        </div>
        <br>
        <br>
      </v-card>
  </div>
</template>

<script>
import db, { storage, functions } from "@/firebase/init";
import moment from 'moment'
export default {
    data() {
        return {
            assignment: null,
        }
    },
    created(){
        db.collection('assignments').doc(this.$route.params.id).get()
        .then(doc => {
            this.assignment = doc.data()
            this.assignment.id = doc.id
        })
    },
    methods: {
        formatCreatedDate(date){
            return moment(date).format('LLLL')
        },
        formatDueDate(date){
            return moment(date).format('dddd, DD MMM YYYY')
        },
        whatsapp(){
            window.open("https://wa.me/6594562307?text=Hello!%20I%20am%20interested%20in%20taking%20assignment%20"+this.assignment.nice_id+"%20%20https://request.codinghero.sg/assignment/"+this.assignment.id ,"_self")
        },
        telegram(){
            window.open("https://telegram.me/codingherosg", "_self")
        }
    }
}
</script>

<style>

</style>